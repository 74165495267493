











































































































import { Component, Vue } from "vue-property-decorator";
import { IconsType } from "@/types";
import {
  mdiAlertCircle,
  mdiDotsVertical,
  mdiDownloadOutline,
  mdiUploadOutline
} from "@mdi/js";

@Component({
  components: {
    CardUserUpload: () => import("@/components/Card/CardUserUpload.vue"),
    DownloadReport: () => import("@/components/DownloadReport.vue")
  }
})
export default class MenuOptions extends Vue {
  //
  // DATA
  private allowed = true;
  private disabled = true;
  private downloadingError = false;
  private dialog = false;
  private switching = false;
  private icons: IconsType = {
    upload: mdiUploadOutline,
    options: mdiDotsVertical,
    download: mdiDownloadOutline,
    warning: mdiAlertCircle
  };
  //
  // METHODS
  public onOkay() {
    this.disabled = false;
    this.dialog = false;
  }
  public switchRestriction() {
    this.switching = true;
    setTimeout(async () => {
      await this.$store.dispatch(
        "AdminModule/putRestrictionStatus",
        !this.allowed
      );
      this.switching = false;
      setTimeout(() => this.fetch());
    });
  }
  async fetch() {
    this.allowed = !(await this.$store.dispatch(
      "AdminModule/getRestrictionStatus"
    ));
  }
  async created() {
    await this.fetch();
  }
}
