var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.AppModule.menus || !_vm.$refs.menu || !_vm.$refs.menu.isActive)?_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"offset-y":"","max-width":"400"},on:{"input":function($event){_vm.$refs.menu.absoluteX = 0;
    _vm.$refs.menu.absoluteY = 0;}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.options))])],1)]}}],null,false,1728411509)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("pages.admin.options.upload-users.title"))+" ")])],1),_c('v-list-item-action',[_c('CardUserUpload')],1)],1),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("pages.admin.options.download-report"))+" ")]),_c('v-list-item-subtitle',{class:_vm.downloadingError ? 'error--text' : ''},[_vm._v(" "+_vm._s(_vm.downloadingError ? _vm.$t("pages.admin.options.download-report-error") : _vm.$t("pages.admin.options.download-report-subtitle"))+" ")])],1),_c('v-list-item-action',[_c('DownloadReport',{on:{"error":function($event){_vm.downloadingError = true},"success":function($event){_vm.downloadingError = false}}})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("pages.admin.options.restrict"))+" "),_c('v-dialog',{attrs:{"offset-y":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.disabled && _vm.allowed),expression:"disabled && allowed"}],attrs:{"icon":"","color":"warning"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.warning))])],1)]}}],null,false,1852831585),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("pages.admin.options.warning-title"))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("pages.admin.options.warning-text"))+" ")]),_c('v-card-actions',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","tile":"","text":""},on:{"click":_vm.onOkay}},[_vm._v(" "+_vm._s(_vm.$t("pages.admin.options.warning-ok-btn"))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"primary",attrs:{"block":"","large":"","tile":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("pages.admin.options.warning-cancel-btn"))+" ")])],1)],1)],1)],1)],1)],1),_c('v-list-item-action',[_c('v-switch',{attrs:{"inset":"","loading":_vm.switching,"disabled":_vm.disabled && _vm.allowed},on:{"change":_vm.switchRestriction},model:{value:(_vm.allowed),callback:function ($$v) {_vm.allowed=$$v},expression:"allowed"}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }